<template>
  <v-container fluid class="px-12">
    <v-row>
      <v-col cols="12" md="3">
        <v-menu ref="menu" v-model="date_picker.menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field hide-details solo v-model="date_rage" label="Search Range" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker color="#45cf9a" @change="getReport" landscape ref="picker" v-model="date_picker.date" range></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <v-select v-model="window_id" @change="getReport" :items="general.windows" item-text="name" item-value="window_id" solo hide-details label="All counter"></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select v-model="service_type_id" @change="getReport" :items="queue_list" item-text="service_type" item-value="service_type_id" multiple solo hide-details label="All queues">
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{ item.service_type }}</span>
            <span v-if="index === 1" class="ml-2 grey--text caption"> (+{{ service_type_id.length - 1 }} others) </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select v-model="user_id" @change="getReport" :items="general.users" :item-text="fullname" item-value="user_id" multiple solo hide-details label="All staffs">
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{ fullname(item) }}</span>
            <span v-if="index === 1" class="ml-2 grey--text caption"> (+{{ user_id.length - 1 }} others) </span>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <dashboardReportCard />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dashboardReportCard from '@/components/cards/dashboardReportCard'

export default {
  name: 'Dashboard',
  components: { dashboardReportCard },
  data() {
    return {
      date_picker: {
        menu: null,
        date: [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      },
      window_id: null,
      user_id: [null],
      service_type_id: [null]
    }
  },
  methods: {
    ...mapActions(['fetchSummaryReport', 'fetchDetailReport']),
    fullname: item => item.firstname + ' ' + item.lastname,
    getReport() {
      this.$nextTick(() => {
        if (this.date_picker.date && this.date_picker.date.length) {
          let params = {
            from_date: this.date_picker.date[0],
            to_date: this.date_picker.date[1]
          }
          if (this.window_id) params.window_id = this.window_id
          if (this.user_id.length && !this.user_id.includes(null)) params.user_id = this.user_id.join(',')
          if (this.service_type_id.length && !this.service_type_id.includes(null)) params.service_type_id = this.service_type_id.join(',')

          this.fetchSummaryReport(params)
          this.fetchDetailReport(params)
        }
      })
    }
  },
  computed: {
    ...mapState(['general']),
    date_rage() {
      if (this.date_picker.date) return this.date_picker.date.join(' ~ ')
      else return null
    },
    queue_list() {
      let queue_list = this.general.queues
      queue_list.unshift({ service_type_id: null, service_type: 'All Queues' })
      return queue_list
    }
  },
  watch: {
    service_type_id(value, old) {
      if (value.includes(null) && !old.includes(null)) {
        this.service_type_id = [null]
      } else {
        if (value.length) {
          if (value.length > 1 && value.includes(null)) {
            this.service_type_id.splice(this.service_type_id.indexOf(null), 1)
          }
        } else {
          this.service_type_id = [null]
        }
      }
    },
    user_id(value, old) {
      if (value.includes(null) && !old.includes(null)) {
        this.user_id = [null]
      } else {
        if (value.length) {
          if (value.length > 1 && value.includes(null)) {
            this.user_id.splice(this.user_id.indexOf(null), 1)
          }
        } else {
          this.user_id = [null]
        }
      }
    },
    'date_picker.menu': {
      immediate: true,
      handler() {
        if (this.date_picker.menu) {
          this.date_picker.date = null
        }
      }
    },
    'date_picker.date': {
      immediate: true,
      handler() {
        // Auto close the date picker if user has selected two date
        if (this.date_picker.date && this.date_picker.date.length === 2) {
          this.date_picker.menu = false
        }
      }
    }
  },
  mounted() {
    this.getReport()
  }
}
</script>
