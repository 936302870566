<template>
  <v-card flat color="transparent">
    <!-- Summary Report -->
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" v-for="info in summary_report" :key="info.title">
        <v-card class="pa-4" height="120">
          <div class="text-h6 summaryCardTitle">{{ info.title }}</div>
          <v-row justify="center" align="center">
            <div class="text-center text-h5 py-2 font-weight-medium" style="display: inline">{{ info.value }}</div>
            <v-btn small fab depressed :style="info.background" style="position: absolute; right: 20px">
              <v-icon color="white" small>{{ info.icon }}</v-icon>
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Chart -->
    <v-row class="mt-4">
      <v-col cols="12" md="7">
        <!-- Ticket Handled Chart -->
        <v-row class="py-4 px-4">
          <v-card style="width: 100%">
            <v-sheet class="v-sheet--offset mx-auto" color="#142749" elevation="12" max-width="calc(100% - 32px)">
              <lineChart chart_title="Handled Ticket" :chart_data="date_distribution" :height="150" />
            </v-sheet>

            <v-card-text class="pt-0">
              <div class="title font-weight-light mb-2">Ticket Handled</div>
              <div class="subheading font-weight-light grey--text">Overview</div>
              <v-divider class="my-2"></v-divider>
              <v-icon class="mr-2" small> mdi-clock </v-icon>
              <span class="caption grey--text font-weight-light">last update - {{ new Date() | moment('YYYY-MM-DD, HH:mm:ss') }}</span>
            </v-card-text>
          </v-card>
        </v-row>

        <!-- Average Waiting Time -->
        <v-row class="py-8 px-4">
          <v-card style="width: 100%">
            <v-sheet class="v-sheet--offset mx-auto" color="#142749" elevation="12" max-width="calc(100% - 32px)">
              <lineChart chart_title="Average Waiting Time (Mins)" :chart_data="average_waiting_time_distribution" :height="150" />
            </v-sheet>

            <v-card-text class="pt-0">
              <div class="title font-weight-light mb-2">Average Waiting Time</div>
              <div class="subheading font-weight-light grey--text">Overview</div>
              <v-divider class="my-2"></v-divider>
              <v-icon class="mr-2" small> mdi-clock </v-icon>
              <span class="caption grey--text font-weight-light">last update - {{ new Date() | moment('YYYY-MM-DD, HH:mm:ss') }}</span>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>

      <!-- Service Type Chart -->
      <v-col cols="12" md="5" class="px-4">
        <v-card style="width: 100%" class="mt-4">
          <v-sheet class="v-sheet--offset mx-auto py-4" color="#142749" elevation="12" max-width="calc(100% - 32px)">
            <pieChart chart_title="Service Types" :chart_data="service_type_distribution" :height="200" />
          </v-sheet>

          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">Service Types</div>
            <div class="subheading font-weight-light grey--text">Overview</div>
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small> mdi-clock </v-icon>
            <span class="caption grey--text font-weight-light">last update - {{ new Date() | moment('YYYY-MM-DD, HH:mm:ss') }}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import pieChart from '@/components/charts/pieChart'
import lineChart from '@/components/charts/lineChart'
import { mapState } from 'vuex'

export default {
  name: 'dashboardReportCard',
  components: { pieChart, lineChart },
  computed: {
    ...mapState(['dashboard']),
    summary_report() {
      return [
        {
          title: 'Total Tickets Served',
          value: this.total_ticket_handled,
          icon: 'far fa-calendar-check',
          background: 'background: linear-gradient(90deg, rgba(243, 108, 63, 1) 35%, rgba(250, 173, 63, 1) 100%)'
        },
        {
          title: 'Tickets Over Time',
          value: this.over_threshold_tickets,
          icon: 'fas fa-stopwatch',
          background: 'background: linear-gradient(130deg, rgba(201, 109, 254, 1) 45%, rgba(109, 6, 112, 1) 100%)'
        },
        {
          title: 'Compliance Ratio',
          value: this.compliance_ratio,
          icon: 'fas fa-percentage',
          background: 'background: linear-gradient(285deg, rgba(198,2,151,0.7), rgba(250,132,97,0.4))'
        },
        {
          title: 'No Show Tickets',
          value: this.no_show_ticket,
          icon: 'fas fa-times',
          background: 'background: linear-gradient(90deg, rgba(239, 61, 90, 1) 35%, rgba(241, 94, 62, 1) 100%)'
        },
        {
          title: 'Abandoned Percentage',
          value: this.abandoned_percentage,
          icon: 'fas fa-trash-alt',
          background: 'background: linear-gradient(109deg, #616a41, #87b97c)'
        },
        {
          title: 'Avg Processing Time',
          value: this.avg_serving_time,
          icon: 'far fa-clock',
          background: 'background: linear-gradient(90deg, rgba(78, 188, 137, 1) 35%, rgba(71, 193, 191, 1) 100%)'
        },
        {
          title: 'Avg Waiting Time',
          value: this.avg_waiting_time,
          icon: 'fas fa-hourglass-half',
          background: 'background: linear-gradient(90deg, rgba(29, 194, 232, 1) 35%, rgba(61, 119, 188, 1) 100%)'
        },
        {
          title: 'Longest waiting time',
          value: this.longest_waiting_time,
          icon: 'fas fa-industry',
          background: 'background: linear-gradient(180deg, #5c4027, #dbb570)'
        }
      ]
    },
    total_ticket_handled() {
      return this.dashboard.card.total_ticket_handled
    },
    over_threshold_tickets() {
      return this.dashboard.card.tickets_over_threshold_count
    },
    avg_serving_time() {
      if (this.dashboard.card.avg_serving_time) return this.dashboard.card.avg_serving_time
      else return '00:00:00'
    },
    avg_waiting_time() {
      if (this.dashboard.card.avg_waiting_time) return this.dashboard.card.avg_waiting_time
      else return '00:00:00'
    },
    longest_waiting_time() {
      if (this.dashboard.card.longest_waiting_time) return this.dashboard.card.longest_waiting_time
      else return '00:00:00'
    },
    no_show_ticket() {
      const { no_show_ticket = 0, queuing_ticket = 0 } = this.dashboard.card
      return `${no_show_ticket}/${queuing_ticket}`
    },
    compliance_ratio() {
      return Math.round(((this.total_ticket_handled - this.over_threshold_tickets) / this.total_ticket_handled) * 100) + '%' || 0 + '%'
    },
    abandoned_percentage() {
      const { no_show_ticket, queuing_ticket = 0 } = this.dashboard.card
      if (no_show_ticket) return Math.round((no_show_ticket / queuing_ticket) * 100) + '%'
      return '0%'
    },
    date_distribution() {
      let chart_data = {
        labels: [],
        data: []
      }
      if (this.dashboard.chart.date_distribution.length) {
        this.dashboard.chart.date_distribution.forEach(data => {
          chart_data.labels.push(this.$moment().format(data.date))
          chart_data.data.push(data.total_ticket_handled)
        })
      }
      return chart_data
    },
    service_type_distribution() {
      let chart_data = {
        labels: [],
        data: []
      }
      if (this.dashboard.chart.service_type_distribution.length) {
        this.dashboard.chart.service_type_distribution.forEach(data => {
          chart_data.labels.push(data.service_type)
          chart_data.data.push(data.total_ticket_handled)
        })
      }
      return chart_data
    },
    average_waiting_time_distribution() {
      let chart_data = {
        labels: [],
        data: []
      }
      if (this.dashboard.chart.average_waiting_time_distribution.length) {
        this.dashboard.chart.average_waiting_time_distribution.forEach(data => {
          chart_data.labels.push(this.$moment(data.date).format('YYYY-MM-DD'))
          const time = Math.round(data.average_waiting_time)
          if (data.average_waiting_time) chart_data.data.push(time)
        })
      }
      return chart_data
    }
  }
}
</script>

<style scoped>
.summaryCardTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
