var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-12",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"","solo":"","label":"Search Range","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date_rage),callback:function ($$v) {_vm.date_rage=$$v},expression:"date_rage"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_picker.menu),callback:function ($$v) {_vm.$set(_vm.date_picker, "menu", $$v)},expression:"date_picker.menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"color":"#45cf9a","landscape":"","range":""},on:{"change":_vm.getReport},model:{value:(_vm.date_picker.date),callback:function ($$v) {_vm.$set(_vm.date_picker, "date", $$v)},expression:"date_picker.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.general.windows,"item-text":"name","item-value":"window_id","solo":"","hide-details":"","label":"All counter"},on:{"change":_vm.getReport},model:{value:(_vm.window_id),callback:function ($$v) {_vm.window_id=$$v},expression:"window_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-select',{attrs:{"items":_vm.queue_list,"item-text":"service_type","item-value":"service_type_id","multiple":"","solo":"","hide-details":"","label":"All queues"},on:{"change":_vm.getReport},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(_vm._s(item.service_type))]):_vm._e(),(index === 1)?_c('span',{staticClass:"ml-2 grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.service_type_id.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.service_type_id),callback:function ($$v) {_vm.service_type_id=$$v},expression:"service_type_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"items":_vm.general.users,"item-text":_vm.fullname,"item-value":"user_id","multiple":"","solo":"","hide-details":"","label":"All staffs"},on:{"change":_vm.getReport},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(_vm._s(_vm.fullname(item)))]):_vm._e(),(index === 1)?_c('span',{staticClass:"ml-2 grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.user_id.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('dashboardReportCard')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }