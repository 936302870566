// CommitChart.js
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    chart_title: {
      type: String,
      default: 'Dummy'
    },
    chart_data: {
      type: Object,
      default: () => {
        return {
          labels: [],
          data: []
        }
      }
    }
  },
  watch: {
    chart_data: {
      handler() {
        this.renderChart(
          {
            labels: this.chart_data.labels,
            datasets: [
              {
                label: this.chart_title,
                backgroundColor: '#6674B7',
                borderColor: '#fff',
                data: this.chart_data.data
              }
            ]
          },
          {
            legend: {
              labels: {
                fontColor: '#fff'
              }
            }
          }
        )
      }
    }
  }
}
