// CommitChart.js
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: {
    chart_data: {
      type: Object,
      default: () => {
        return {
          labels: [],
          data: []
        }
      }
    }
  },
  watch: {
    chart_data() {
      this.renderChart(
        {
          labels: this.chart_data.labels,
          datasets: [
            {
              data: this.chart_data.data,
              backgroundColor: ['rgba(255, 99, 132)', 'rgba(54, 162, 235)', 'rgba(255, 206, 86)', 'rgba(75, 192, 192)', 'rgba(153, 102, 255)', 'rgba(255, 159, 64)'],
              borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
              legend: {
                labels: {
                  defaultFontColor: '#fff'
                }
              }
            }
          ]
        },
        {
          legend: {
            labels: {
              fontColor: '#fff'
            }
          }
        }
      )
    }
  }
}
